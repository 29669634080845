import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';

export default function DashBilling() {
  const [loggedIn, setLoggedIn] = useState(true);

  return (
    <>
    {
      !loggedIn ? <></> :
      <DashboardLayout
        title={'Billing'}

      >
        <div>

        </div>
      </DashboardLayout>
    }
    </>
  )
}
import { createBrowserRouter } from 'react-router-dom';

import Signup from './pages/signup.js';
import Verify from './pages/verify.js';
import Login from './pages/login.js';

import DashBilling from './pages/dash-billing.js';
import DashSettings from './pages/dash-settings.js';

/**   */
import Home from './pages/home.js';
import CreateChar from './pages/create-char.js';
import CreateImg from './pages/create-img.js';
import CategoriesMain from './pages/categories-main.js';
import CategoriesDynamic from './pages/categories-dynamic.js';
import DynamicCharacter from './pages/character-dynamic.js'
import CharacterChat from './pages/character-chat.js';

import UserProfile from './pages/user-profile.js';
import UserChats from './pages/user-chats.js';

import TopCreators from './pages/top-creators.js';
import ForYou from './pages/for-you.js';
import Favorites from './pages/favorites.js';
import YourCharacters from './pages/your-characters.js'
import Following from './pages/following.js';

import YourImages from './pages/your-images.js';

import UserMembership from './pages/membership/user-membership.js';
import ManageMembership from './pages/membership/manage-membership.js';

import SearhCharacters from './pages/search-characters.js';

import About from './pages/about.js';
import CommunityGuidelines from './pages/legal/community-guidelines.js';
import TermsOfService from './pages/legal/terms.js';
import PrivacyPolicy from './pages/legal/Privacy.js';
import UnderagePolicy from './pages/legal/underage.js';
import ContentRemovalPolicy from './pages/legal/content-removal.js';
import BlockedContentPolicy from './pages/legal/blocked-content.js';
import DMCAPolicy from './pages/legal/dmca.js';
import ComplaintPolicy from './pages/legal/complaint.js';
import ComplianceStatement from './pages/legal/2257-exemption.js';
import LegalInformation from './pages/legal/legal.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/legal",
    element: <LegalInformation />,
  },
  {
    path: "/legal/guidelines",
    element: <CommunityGuidelines />,
  },
  {
    path: "/legal/terms",
    element: <TermsOfService />,
  },
  {
    path: "/legal/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/legal/underage",
    element: <UnderagePolicy />,
  },  
  {
    path: "/legal/content-removal",
    element: <ContentRemovalPolicy />,
  },
  {
    path: "/legal/blocked-content",
    element: <BlockedContentPolicy />,
  },
  {
    path: "/legal/dmca",
    element: <DMCAPolicy />,
  },
  {
    path: "/legal/usc-2257-exemption",
    element: <ComplianceStatement />,
  },
  {
    path: "/complaint",
    element: <ComplaintPolicy/>,
  },
  {
    path: "/search",
    element: <SearhCharacters />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/top-creators",
    element: <TopCreators />,
  },
  {
    path: "/favorites",
    element: <Favorites />,
  },
  {
    path: "/my-characters",
    element: <YourCharacters />,
  },
  {
    path: "/my-images",
    element: <YourImages />,
  },
  {
    path: "/following",
    element: <Following />,
  },
  {
    path: "/for-you",
    element: <ForYou />,
  },
  {
    path: '/character/create',
    element: <CreateChar/>
  },
  {
    path: '/image/create',
    element: <CreateImg/>
  },
  {
    path: '/character/edit',
    element: <CreateChar/>
  },
  {
    path: '/categories',
    element: <CategoriesMain/>
  },
  {
    path: "tag/:categoryname",
    element: <CategoriesDynamic />,
  },
  {
    path: "creator/:username",
    element: <UserProfile />,
  },
  {
    path: '/chats',
    element: <UserChats/>
  },
  {
    path: '/membership',
    element: <UserMembership/>
  },
  {
    path: '/account',
    element: <ManageMembership/>
  },
  {
    path: 'character/:charactername',
    element: <DynamicCharacter/>
  },
  {
    path: 'character/:charactername/chat',
    element: <CharacterChat/>
  },
  {
    path: "/billing",
    element: <DashBilling />,
  },
  {
    path: "/settings",
    element: <DashSettings />,
  }
]);

export default router;

import { useState, useEffect } from 'react';
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';
import styles from '../../assets/pages/UserMembership.module.scss';
import axiosRequest from '../../utils/request';

import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

let topCreators = [
  { username: "nsfwBabe", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/1.webp" },
  { username: "fondlercreator", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/10.webp" },
  { username: "nexubundas9", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/11.jpg" },
  { username: "lovaofpixls", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/12.jpeg" },
  { username: "lunaWave", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/13.jpg" },
  { username: "ghostedMod", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/15.jpg" },
  { username: "echo.77", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/16.webp" },
  { username: "neonPulse", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/17.jpg" },
  { username: "riftWalker", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/18.jpg" },
  { username: "cryptic_8", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/19.avif" },
  { username: "frostbyte", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/2.avif" },
  { username: "zenith.23", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/20.avif" },
  { username: "jake.zen", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/21.webp" },
  { username: "mystic_13", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/22.jpeg" },
  { username: "nova.9", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/23.jpeg" },
  { username: "byteKnight", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/24.jpeg" },
  { username: "glitch.99", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/25.jpeg" },
  { username: "phantomX", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/3.webp" },
  { username: "starlight.7", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/4.avif" },
  { username: "obsidian3", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/5.webp" },
  { username: "hyperFlux", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/6.jpeg" },
  { username: "quantum.2", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/7.jpeg" },
  { username: "leo.rift", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/8.jpeg" },
  { username: "sara.nova", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/9.jpeg" },
  { username: "emily.wander", pfp: "https://s3.amazonaws.com/mindscape.is/pfp/9.jpeg" }
];

export default function UserMembership() {
  const navigate = useNavigate();

  const loggedIn = useSelector(state => state.loggedIn)
  const [selectedBilling, setSelectedBilling] = useState('monthly');
  const [openFAQ, setOpenFAQ] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.userData);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const faqItems = [
    {
      question: "Why was my payment card declined?",
      answer: "Your card may have been declined due to insufficient funds, expired card details, or security measures by your bank. Please check your card details and try again, or contact your bank for assistance."
    },
    {
      question: "Do you offer PayPal?",
      answer: "We currently do not offer PayPal as a payment method. We use Whop for secure payment processing."
    },
    {
      question: "How do you ensure my data's integrity?",
      answer: "We use industry-standard encryption and security measures to protect your data. All transactions are processed securely through Whop."
    },
    {
      question: "How do I cancel my subscriptions?",
      answer: "You can cancel your subscription at any time from your account settings. Your access will continue until the end of your current billing period."
    },
    {
      question: "Is cryptocurrency a valid payment method?",
      answer: "We do not currently accept cryptocurrency as a payment method. We use Whop for all transactions."
    },
    {
      question: "How do I know you're the best on the market?",
      answer: "We strive to provide the best service possible. We encourage you to try our service and compare it with others to see how we stack up."
    }
  ];


  const getPriceId = (planType, billingCycle) => {
    const priceIds = {
      premium: {
        monthly: 'price_1PzLJpDqfFzJp3s7vn6hphXV',
        yearly: 'price_1PzLJpDqfFzJp3s7x4NmekBS',
      },
      deluxe: {
        monthly: 'price_1PzLJpDqfFzJp3s7koEbSc98',
        yearly: 'price_1PzLJpDqfFzJp3s72Xunoozd',
      },
    };
    return priceIds[planType][billingCycle];
  };

  const [selectedPlan, setSelectedPlan] = useState('Deluxe');

  const handlePlanSelect = (planType) => {
    setSelectedPlan(planType);
  };

  const handleSubscribe = async () => {
    if (loggedIn) {
      let selectedPriceId = getPriceId(selectedPlan.toLowerCase(), selectedBilling.toLowerCase());
      window.open(`https://aibuddy.fun/membership?id=${user['_id']}&priceId=${selectedPriceId}`)
    } else {
      navigate('/signup', { state: { redirectPath: '/membership' }  });
    }
    return;
  };

  const handleCreatorClick = (username) => {
    navigate(`/creator/${username}`);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <DashboardLayout title={''} headerStyle={{ padding: '0px' }}>
        <div className={styles.container}>
          <header className={styles.header}>
            <div className={styles.content}>
              <h1>Join our 2400+ creators community</h1>
              <p>Upgrade to deluxe today and get access to generating images with your own perfect companion!</p>
            </div>
            <div className={styles.imageGrid}>
              {topCreators.slice(0, isMobile ? 10 : 22).map((creator, index) => (
                <div
                  key={index}
                  className={styles.circleImage}
                  onClick={() => handleCreatorClick(creator.username)}
                  style={{
                    backgroundImage: `url(${creator.pfp})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    cursor: 'pointer',
                  }}
                ></div>
              ))}
            </div>
          </header>
          <div className={styles.ratingSection}>
            <div className={styles.rating}>
              <span>⭐⭐⭐⭐⭐</span>
              <p>Rated 4.8/5</p>
            </div>
            <div className={styles.users}>
              <span>🌎</span>
              <p>Join 100k+ users across the globe!</p>
            </div>
          </div>

          <div className={styles.planSelector}>
            <h2>Choose your Plan</h2> 
            <p>100% anonymous. You can cancel anytime.</p>
            <p><b>Use code WELCOME100 at checkout</b></p>
            <div className={styles.billingToggle}>
              <button
                className={selectedBilling === 'monthly' ? styles.active : ''}
                onClick={() => setSelectedBilling('monthly')}
              >
                Monthly billing
              </button>
              <button
                className={selectedBilling === 'yearly' ? styles.active : ''}
                onClick={() => setSelectedBilling('yearly')}
              >
                Yearly billing
                <span className={styles.discount}>-30%</span>
              </button>
            </div>
          </div>

          <div className={styles.plans}>
            <div
              className={`${styles.plan} ${selectedPlan === 'Premium' ? styles.selectedPlan : ''}`}
              onClick={() => handlePlanSelect('Premium')}
            >
              <h3>Premium</h3>
              <p className={styles.price}>
                ${selectedBilling === 'monthly' ? '9' : '99'}<span>/{selectedBilling === 'monthly' ? 'month' : 'year'}</span>
              </p>
              <p className={styles.description}>Premium models and chat history. All the fun at the price of a coffee.</p>
              <ul>
                <li>5,000 messages / month</li>
                <li>Chat History</li>
                <li>Premium Models</li>
                <li>NSFW</li>
                <li>Custom Characters</li>
                <li>Premium Creator badge</li>
              </ul>
            </div>
            <div
              className={`${styles.plan} ${styles.bestValue} ${selectedPlan === 'Deluxe' ? styles.selectedPlan : ''}`}
              onClick={() => handlePlanSelect('Deluxe')}
            >
              <span className={styles.bestValueTag}>Best Value</span>
              <h3>Deluxe</h3>
              <p className={styles.price}>
                ${selectedBilling === 'monthly' ? '14' : '149'}<span>/{selectedBilling === 'monthly' ? 'month' : 'year'}</span>
              </p>
              <p className={styles.description}>All-access - Premium + uncensored NSFW chats. Bestseller for a reason 😉</p>
              <ul>
                <li>20,000 messages / month</li>
                <li>Chat History</li>
                <li>Premium Models</li>
                <li>NSFW</li>
                <li>Custom Characters</li>
                <li>8K Memory</li>
                <li>Custom Images</li>
                <li>Deluxe Creator badge</li>
                <li>Voice ( SOON )</li>
                <li>In-Chat Pictures ( SOON )</li>
              </ul>
            </div>
          </div>

          <div className={styles.subscribeButtonContainer}>
            <button
              className={styles.checkoutButton}
              onClick={handleSubscribe}
              disabled={!selectedPlan || loading}
            >
              {loading ? 'Processing...' : `Subscribe to ${selectedPlan || 'Selected Plan'}`}
            </button>
          </div>

          <div className={styles.securityInfo}>
            <p>🔒 Safe & Secure Payments</p>
            <p>💳 Privacy in Bank transactions</p>
          </div>

          <div className={styles.communityFeedback}>
            <h2>Community Feedback <span role="img" aria-label="envelope">✉️</span></h2>
            <p>Here's what users say about us!</p>
            <div className={styles.feedbackGrid}>
              <div className={styles.feedbackItem}>
                <p>"TBH whatever model they're using is DIFFERENT. Hella realistic and WILD"</p>
                <div className={styles.userInfo}>
                  <img src="https://s3.amazonaws.com/mindscape.is/pfp/19.avif" alt="User" />
                  <span>cryptic_8 - via Discord</span>
                </div>
                <div className={styles.rating}>⭐⭐⭐⭐⭐</div>
              </div>
              <div className={styles.feedbackItem}>
                <p>"gotta hand it to y'all site is an absolute insane..."</p>
                <div className={styles.userInfo}>
                  <img src="https://s3.amazonaws.com/mindscape.is/pfp/25.jpeg" alt="User" />
                  <span>Kolma - via Twitter</span>
                </div>
                <div className={styles.rating}>⭐⭐⭐⭐⭐</div>
              </div>
              <div className={styles.feedbackItem}>
                <p>"Def upgrade to deluxe if you can!!!!"</p>
                <div className={styles.userInfo}>
                  <img src="https://s3.amazonaws.com/mindscape.is/pfp/12.jpeg" alt="User" />
                  <span>domatu_39 - via Discord</span>
                </div>
                <div className={styles.rating}>⭐⭐⭐⭐⭐</div>
              </div>
              <div className={styles.feedbackItem}>
                <p>"I was on girlfriendGPT but using Mindscape is smoother, better convos, & more options."</p>
                <div className={styles.userInfo}>
                  <img src="https://s3.amazonaws.com/mindscape.is/pfp/1.webp" alt="User" />
                  <span>nsfwBabe - via Twitter</span>
                </div>
                <div className={styles.rating}>⭐⭐⭐⭐⭐</div>
              </div>
            </div>
          </div>

          <div className={styles.faqSection}>
            <h2>Frequently Asked Questions</h2>
            {faqItems.map((item, index) => (
              <div key={index} className={styles.faqItem}>
                <button onClick={() => toggleFAQ(index)}>
                  {item.question}
                  <span>{openFAQ === index ? '▲' : '▼'}</span>
                </button>
                {openFAQ === index && <p>{item.answer}</p>}
              </div>
            ))}
          </div>

          <footer className={styles.footer}>
            <p>Didn't find what you are looking for? Reach out to us on Discord</p>
          </footer>
        </div>
      </DashboardLayout>
    </>
  );
}
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../assets/pages/Creators.module.scss';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import ContentContainer from '../components/ContentContainer/ContentContainer';

import Button from '../components/Button/Button';
import Input from '../components/Input/Input';

import axiosRequest from '../utils/request';

import capitalizeFirstLetter from '../utils/capitalizeFirstLetter';

import { toast } from 'react-toastify';

export default function DashHome() {
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(true);

  const userRedux = useSelector(state => state.userData);
  const [user, setUser] = useState(userRedux);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  useEffect(() => {

  }, []);

  async function updateUser() {
    try {
      let token = localStorage.getItem('jwt');

      if (!token) {

        // Log them out here and redirect to login
        return;
      };

      let payload = {...user};

      let result = await axiosRequest('post', 'editTeamMember', payload, token);

      if (result.data.success) {
        dispatch({
          type: 'UPDATE_USER_DATA',
          data: payload
        });

        toast.success('Successfully updated!')
      } else {
        // throw error
      }
    } catch (e) {
      console.log('Error @ updateUser -> ', e);
    }
  };

  async function updatePassword() {
    try {
      if(newPassword != newPasswordConfirm) {
        toast.error("New password's don't match!");
        return;
      };

      if(currentPassword.length < 1 || newPassword.length < 1 || newPasswordConfirm.length < 1) {
        toast.error("You missed a field!");
        return;
      };

      let payload = {
        currentPassword,
        newPassword
      };


      let token = localStorage.getItem('jwt');

      if (!token) {

        // Log them out here and redirect to login
        return;
      };

      let result = await axiosRequest('post', 'updatePassword', payload, token);

      if (result.data.success) {
        toast.success(result.data.msg);
      } else {
        toast.error(result.data.msg);
      };

      setCurrentPassword('');
      setNewPassword('');
      setNewPasswordConfirm('');
    } catch(e) {
      console.log('error @ updatePassword -> ', e);
    }
  }

  return (
    <>
      {
        !loggedIn ? <></> :
          <DashboardLayout
            title={'Settings'}

          >
            <div className={styles.topRow}>
              <ContentContainer styleObj={{ borderRadius: '15px' }}>
                <h1 style={{ fontSize: '20px', margin: '10px' }}>Personal Information</h1>
                <div style={{ margin: '10px' }}>
                  <Input
                    value={user.userId}
                    onChange={e => {

                    }}
                    label={"User ID"} id={'userId'}
                    type={'text'}
                    style={{ maxWidth: '415px', fontSize: '12px' }}
                    disabled={true}
                  />
                </div>
                <div style={{ margin: '10px' }}>
                  <Input
                    value={user.email}
                    onChange={e => {

                    }}
                    label={"Email"} id={'email'}
                    type={'text'}
                    style={{ maxWidth: '415px', fontSize: '12px' }}
                    disabled={true}
                  />
                </div>
                <div style={{ margin: '10px' }}>
                  <Input
                    value={user.name}
                    onChange={e => {
                      let newObj = {...user};

                      newObj.name = e.target.value;

                      setUser(newObj);
                    }}
                    label={"Your Name"} id={'name'}
                    type={'text'}
                    style={{ maxWidth: '415px', fontSize: '12px' }}
                  />
                </div>
                <div style={{ margin: '10px' }}>
                  <Input
                    value={user.telegram}
                    placeholder={'@'}
                    onChange={e => {
                      let newObj = {...user};

                      newObj.telegram = e.target.value;

                      setUser(newObj);
                    }}
                    label={<p>Your Telegram <small style={{ color: '#000' }}>(optional)</small></p>} id={'telegram'}
                    type={'text'}
                    style={{ maxWidth: '415px', fontSize: '12px' }}
                  />
                </div>
                <Button
                  style={{
                    background: '#DD8DEE40',
                    transition: '.15s ease-in-out',
                    margin: '10px',
                    width: '100%',
                    maxWidth: '415px',
                  }}
                  alt
                  onClick={async () => {
                    updateUser();
                  }}
                >Update</Button>
              </ContentContainer>
              <ContentContainer highlighted styleObj={{ borderRadius: '15px' }}>
                <h1 style={{ fontSize: '20px', margin: '10px' }}>Update Password</h1>
                <div style={{ margin: '10px' }}>
                  <Input
                    value={currentPassword}
                    onChange={e => {
                      setCurrentPassword(e.target.value);
                    }}
                    label={"Current Password"} id={'current-password'}
                    type={'password'}
                    style={{ maxWidth: '415px', fontSize: '12px' }}
                  />
                </div>
                <div style={{ margin: '10px' }}>
                  <Input
                    value={newPassword}
                    onChange={e => {
                      setNewPassword(e.target.value);
                    }}
                    label={"New Password"} id={'new-password'}
                    type={'password'}
                    style={{ maxWidth: '415px', fontSize: '12px' }}
                  />
                </div>
                <div style={{ margin: '10px' }}>
                  <Input
                    value={newPasswordConfirm}
                    onChange={e => {
                      setNewPasswordConfirm(e.target.value);
                    }}
                    label={"Confirm New Password"} id={'new-password-confirm'}
                    type={'password'}
                    style={{ maxWidth: '415px', fontSize: '12px' }}
                  />
                </div>
                <Button
                  style={{
                    background: '#DD8DEE40',
                    transition: '.15s ease-in-out',
                    margin: '10px',
                    width: '100%',
                    maxWidth: '415px',
                  }}
                  alt
                  onClick={async () => {
                    updatePassword();
                  }}
                >Update Password</Button>
              </ContentContainer>
            </div>
          </DashboardLayout>
      }
    </>
  )
}
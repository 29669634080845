import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';

import styles from '../assets/pages/About.module.scss';

import aboutData from '../resources/about';

import animateTyping from '../utils/animateTyping';

import sleep from '../utils/sleep';

export default function About() {
  const [loggedIn, setLoggedIn] = useState(true);

  const [section, setSection] = useState(5);
  const [initialLoad, setInitialLoad] = useState(true);

  const [section1Text, setSection1Text] = useState(aboutData['section1']);
  const [section2Text, setSection2Text] = useState(aboutData['section2']);
  const [section3Text, setSection3Text] = useState(aboutData['section3']);
  const [section4Text, setSection4Text] = useState(aboutData['section4']);
  const [section5Text, setSection5Text] = useState(aboutData['section5']);
  const [section6Text, setSection6Text] = useState(aboutData['section6']);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videos = [aboutData['video1'], aboutData['video2'], aboutData['video3'], aboutData['video4']];
  const labels = ['Discover endless companions', 'Chat. No boundries. No Limits.'];
  const videoRef = useRef();

  const [currentLabel, setCurrentLabel] = useState(labels[0]);
  useEffect(() => {
    if (initialLoad) {
      // type out first section
      /*setTimeout(async () => {
        //await loadInitialText();
        // Start playing the video after the final text is typed out
        setCurrentVideoIndex(0);
      }, 1000);*/

      setInitialLoad(false);
    }
  }, []);

  async function loadInitialText() {
    setSection(1);
    await animateTyping(aboutData['section1'], setSection1Text);
    await sleep(5000);

    setSection(2);
    await animateTyping(aboutData['section2'], setSection2Text);
    await sleep(4000);

    setSection(3);
    await animateTyping(aboutData['section3'], setSection3Text);
    await sleep(1500);

    setSection(4);
    await animateTyping(aboutData['section4'], setSection4Text);
    await sleep(3000);

    setSection(5);
    await animateTyping(aboutData['section5'], setSection5Text);
    await sleep(3000);

    setSection(6);
    await animateTyping(aboutData['section6'], setSection6Text);
    await sleep(1000);
  }

  const handleScroll = (direction) => {
    setCurrentVideoIndex((prevIndex) => {
      if (direction === 'left') {
        return prevIndex > 0 ? prevIndex - 1 : videos.length - 1;
      } else {
        return (prevIndex + 1) % videos.length;
      }
    });
  };

  useEffect(() => {
    setCurrentLabel(labels[currentVideoIndex])
    videoRef.current?.load();
  }, [currentVideoIndex])

  const crtRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [gridSizeX, setGridSizeX] = useState(10);
  const [gridSizeY, setGridSizeY] = useState(10);
  const [flickerRate, setFlickerRate] = useState(0.02);
  const [frameRate, setFrameRate] = useState(50);

  useEffect(() => {
    const crt = crtRef.current;
    if (!crt) return;

    const updateSize = () => {
      setWidth(crt.offsetWidth);
      setHeight(crt.offsetHeight);
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    const createCircles = () => {
      crt.innerHTML = ''; // Clear existing circles
      for (let x = 0; x < width; x += gridSizeX) {
        for (let y = 0; y < height; y += gridSizeY) {
          const circle = document.createElement('div');
          circle.className = styles.circle;
          circle.style.left = `${x}px`;
          circle.style.top = `${y}px`;
          circle.dataset.x = x;
          circle.dataset.y = y;
          crt.appendChild(circle);
        }
      }
    };

    const animateCircles = () => {
      const circles = crt.querySelectorAll(`.${styles.circle}:not(.moving)`);
      circles.forEach(circle => {
        if (Math.random() < flickerRate) {
          circle.style.opacity = Math.random() < 0.5 ? '1' : '0';
        }
      });
    };

    const moveCircles = () => {
      const circles = Array.from(crt.querySelectorAll(`.${styles.circle}`));
      circles.sort((a, b) => {
        const distA = Math.sqrt((0 - a.dataset.x) ** 2 + (height - a.dataset.y) ** 2);
        const distB = Math.sqrt((0 - b.dataset.x) ** 2 + (height - b.dataset.y) ** 2);
        return distB - distA; // Reverse the order to start from bottom left
      });

      const totalCircles = circles.length;
      const durationPerCircle = 6 / totalCircles; // Distribute 10 seconds across all circles

      circles.forEach((circle, index) => {
        setTimeout(() => {
          circle.classList.add('moving');
          const duration = .75 + Math.random() * 0.5; // Random duration between 0.5-1 second
          circle.style.transition = `all ${duration}s ease-out`;
          circle.style.transform = 'translate(100vw, -100vh)';
          circle.style.opacity = '0';
        }, index * durationPerCircle * 1000); // Stagger the start of each circle's movement
      });
    };

    createCircles();
    const flickerInterval = setInterval(animateCircles, 1000 / frameRate);

    // Start movement after 3-4 seconds
    /*const movementTimeout = setTimeout(() => {
      moveCircles();
    }, 2500 + Math.random() * 1000);*/ // Random time between 3-4 seconds

    return () => {
      clearInterval(flickerInterval);
      //clearTimeout(movementTimeout);
      window.removeEventListener('resize', updateSize);
    };
  }, [width, height, gridSizeX, gridSizeY, flickerRate, frameRate]);

  return (
    <>
      {!loggedIn ? (
        <></>
      ) : (
        <DashboardLayout title={''}>
          <div className={styles.container}>
            <div className={styles.crt} ref={crtRef}></div>
            <div className={styles.column}>
              {[section1Text, section2Text, section3Text, section4Text, section5Text, section6Text].map((text, index) => (
                <p key={index} className={styles.text}>
                  {text} {section === index + 1 && <span className={styles.blinker} />}
                </p>
              ))}
              {currentVideoIndex !== null && (
                <div className={styles.videoContainer}>
                  <h2>{currentLabel}</h2>
                  <video ref={videoRef} autoPlay muted loop playsInline className={styles.video}>
                    <source src={videos[currentVideoIndex]} type="video/mp4" />
                  </video>
                  <div className={styles.scrollControls}>
                    <button onClick={() => handleScroll('left')}>←</button>
                    <span>Scroll left-right to see all</span>
                    <button onClick={() => handleScroll('right')}>→</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DashboardLayout>
      )}
    </>
  );
}
import styles from './SideNavigation.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Button from '../Button/Button';
import LogoutIcon from '../../assets/icons/LogoutIcon.js';
import { useState, useEffect } from 'react';
import LargeCloseIcon from '../../assets/icons/LargeCloseIcon.js';

import CustomDropdown from '../Dropdown'

import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import Input from '../Input/Input.js';

import axiosRequest from '../../utils/request';


const SideNavigation = ({ menuOpen, setMenuOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(true);
  const user = useSelector(state => state.userData);
  const loggedIn = useSelector(state => state.loggedIn);

  function formatDate() {
    const d = new Date();
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();

    return [month.padStart(2, '0'), day.padStart(2, '0'), year].join('/');
  }

  async function logout() {
    try {
      navigate('/login');
      localStorage.removeItem('jwt');
      dispatch({
        data: null,
        type: 'UPDATE_USER_DATA'
      });

      dispatch({
        data: false,
        type: 'UPDATE_LOGGED_IN'
      });

      handleLogout();
    } catch (e) {
      console.log('ERROR: ', e);
    }
  };

  async function handleLogout() {
    try {
      const result = await axiosRequest('post', 'logout', {});
      if (result.data.success) {
        toast.info('Please log back in.');
        return;
      }
    } catch (e) {
      console.log('Error @ handleLogout -> ', e);
      return;
    }
  }

  const createOption = ['Character', 'Image'];

  const handleCreateOption = (option) => {
    switch (option) {
      case 'Character':
        navigate('/character/create');
        break;
      case 'Image':
        //toast.info('Coming Soon!');
        navigate('/image/create');
        break;
    }
  }

  //hovered ? { width: '250px', transition: '.05s ease-in-out' } : { width: '85px', transition: '.05s ease-in-out' }
  return (
    <nav
      style={{}}
      className={clsx([styles.nav, menuOpen && styles.navOpen])}
      onMouseEnter={e => {
        e.preventDefault();
        //setHovered(true);
      }}
      onMouseLeave={e => {
        e.preventDefault();
        //setHovered(false);
      }}
    >
      <button className={styles.menuButton} onClick={() => setMenuOpen(false)}><LargeCloseIcon /></button>
      <div>
        <div className={styles['desktop-only']} style={{ margin: '20px' }}>
          {(
            <Button
              onClick={() => navigate('/')}
              className={styles.hoverScaleButton}
            >
              🏠
              {hovered ? ' Home' : ''}
            </Button>
          )}
        </div>
        <ul className={styles.scrollable}>
          <div className={clsx(styles.userArea, styles['mobile-only'])}>
            <div className={styles['mobile-only']} style={{ marginTop: '75px' }}>
              {(
                <Button
                  onClick={() => navigate('/')}
                  className={styles.hoverScaleButton}
                >
                  🏠
                  {hovered ? ' Home' : ''}
                </Button>
              )}
            </div>
            <div style={{ marginTop: '10px', marginBottom: '10px' }} className={clsx(styles['mobile-only'], styles.row)}>
              {(
                <>
                  <div>
                    <Button
                      onClick={() => navigate('/character/create')}
                      className=""
                      style={{
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                        width: '150px'
                      }}
                    >
                      + Create
                    </Button>
                  </div>
                  <div>
                    <CustomDropdown
                      options={createOption}
                      value={'➡️'}
                      onChange={handleCreateOption}
                      create={true}
                      chat={false}
                      removeArrow
                      emojiSelector
                      selectedStyle={
                        {
                          backgroundColor: '#fff !important',
                          color: '#000',
                          borderLeft: '1px dashed #000',
                          width: '35px',
                          padding: '14.25px 5px',
                          borderTopLeftRadius: '0px',
                          borderBottomLeftRadius: '0px',
                        }
                      }
                    />
                  </div>
                </>
              )}
            </div>
            {hovered ? (
              <div className={styles.user}>
                {loggedIn ? <div className={'mobile-hidden'}>
                  <Input
                    placeholder={'Click here to search for characters'}
                    style={{ width: '100%', cursor: 'pointer', marginBottom: '10px' }}
                    onClick={e => {
                      e.preventDefault();
                      navigate('/search')
                    }}
                  />
                </div> : <></>}
                <button
                  className={styles.userButton}
                  onClick={(e) => {
                    e.preventDefault();

                    if (loggedIn) {
                      logout()
                    } else {
                      navigate('/login')
                    }

                  }}
                >
                  <div className={styles.info}>
                    {loggedIn ? <><span className={styles.userEmail}>{user ? user.email : ''}</span>
                      <span className={styles.userSignIn}>Last Sign In: {formatDate()}</span></>
                      :
                      <span>Login</span>
                    }
                  </div>
                  <LogoutIcon />
                </button>
              </div>
            ) : null}
          </div>
          <li>
            <NavLink
              to={"/following"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Following">👥</span>
              {hovered ? <span>Following</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/top-creators"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Top Creators">🏆</span>
              {hovered ? <span>Top Creators</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/membership"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Membership">💳</span>
              {hovered ? <span>Membership</span> : ''}
            </NavLink>
          </li>
          <hr />
          {loggedIn ? <><li>
            <NavLink
              to={"#"}
              className={({ isActive }) => clsx([isActive && styles.activeCategory])}
            >
              <span style={{ fontSize: '32px' }} role="img" aria-label="For You">❤️</span>
              {hovered ? <span style={{ fontSize: '18px' }}>You</span> : ''}
            </NavLink>
          </li>
            <li>
              <NavLink
                to={"/for-you"}
                className={({ isActive }) => clsx([isActive && styles.active])}
              >
                <span role="img" aria-label="For You">🔍</span>
                {hovered ? <span>For You</span> : ''}
              </NavLink>
            </li>
            <><li>
              <NavLink
                to={`/creator/${user ? user.username : ''}`}
                className={({ isActive }) => clsx([isActive && styles.active])}
              >
                <span role="img" aria-label="Profile">👤</span>
                {hovered ? <span>Profile</span> : ''}
              </NavLink>
            </li>
             {/* <li>
                <NavLink
                  to={`/account`}
                  className={({ isActive }) => clsx([isActive && styles.active])}
                >
                  <span role="img" aria-label="Account">🧰</span>
                  {hovered ? <span>Manage Account</span> : ''}
                </NavLink>
              </li>*/}
              <li>
                <NavLink
                  to={"/chats"}
                  className={({ isActive }) => clsx([isActive && styles.active])}
                >
                  <span role="img" aria-label="Chats">💬</span>
                  {hovered ? <span>Chats</span> : ''}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/my-characters"}
                  className={({ isActive }) => clsx([isActive && styles.active])}
                >
                  <span role="img" aria-label="Characters">🦸‍♂️</span>
                  {hovered ? <span>Characters</span> : ''}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/my-images"}
                  className={({ isActive }) => clsx([isActive && styles.active])}
                >
                  <span role="img" aria-label="Images">🖼️</span>
                  {hovered ? <span>Images</span> : ''}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/favorites"}
                  className={({ isActive }) => clsx([isActive && styles.active])}
                >
                  <span role="img" aria-label="Favorites">⭐</span>
                  {hovered ? <span>Favorites</span> : ''}
                </NavLink>
              </li></>
          </> : <></>
          }
          <hr />
          <li>
            <NavLink
              to={"#"}
              className={({ isActive }) => clsx([isActive && styles.activeCategory])}
            >
              <span style={{ fontSize: '32px' }} role="img" aria-label="Explore">🔍</span>
              {hovered ? <span style={{ fontSize: '18px' }}>Explore</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/categories"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span style={{ fontSize: '18px' }} role="img" aria-label="Categories">📚</span>
              {hovered ? <span style={{ fontSize: '18px' }}>Categories</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/female"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Female">👩‍🦰</span>
              {hovered ? <span>Female</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/nsfw"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="NSFW">🔥</span>
              {hovered ? <span>NSFW</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/scenario"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Scenario">🪢</span>
              {hovered ? <span>Scenario</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/dominant"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Dominant">⛓️</span>
              {hovered ? <span>Dominant</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/submissive"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Submissive">🙇</span>
              {hovered ? <span>Submissive</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/erotic-roleplay"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Erotic Roleplay">💋</span>
              {hovered ? <span>Erotic Roleplay</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/fictional"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Fictional">📚</span>
              {hovered ? <span>Fictional</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/multiple"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Multiple">👭</span>
              {hovered ? <span>Multiple</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/oc"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Original Character (OC)">🧑‍🎨</span>
              {hovered ? <span>Original Character (OC)</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/petite"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Petite">📏</span>
              {hovered ? <span>Petite</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/breeding"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Breeding">🍼</span>
              {hovered ? <span>Breeding</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/femdom"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Femdom">👠</span>
              {hovered ? <span>Femdom</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/milf"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Milf">👩‍🦳</span>
              {hovered ? <span>Milf</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/straight"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Straight">🚹</span>
              {hovered ? <span>Straight</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/cheating"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Cheating">💔</span>
              {hovered ? <span>Cheating</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/rpg"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="RPG">🎲</span>
              {hovered ? <span>RPG</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/monster-girl"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Monster Girl">👧</span>
              {hovered ? <span>Monster Girl</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/cuckold"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Cuckold">👫</span>
              {hovered ? <span>Cuckold</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/bully"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Bully">💪</span>
              {hovered ? <span>Bully</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/non-human"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Non-human">🦄</span>
              {hovered ? <span>Non-human</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/switch"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Switch">🔄</span>
              {hovered ? <span>Switch</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/anime"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Anime">📺</span>
              {hovered ? <span>Anime</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/magical"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Magical">🔮</span>
              {hovered ? <span>Magical</span> : ''}
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"/tag/bbw"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="BBW">🍔</span>
              {hovered ? <span>BBW</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/monster"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Monster">👹</span>
              {hovered ? <span>Monster</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/furry"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Furry">🪮</span>
              {hovered ? <span>Furry</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/game"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Game">🎮</span>
              {hovered ? <span>Game</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/hentai"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Hentai">🎌</span>
              {hovered ? <span>Hentai</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/villain"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Villain">🦹‍♂️</span>
              {hovered ? <span>Villain</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/chastity"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Chastity">⛓️</span>
              {hovered ? <span>Chastity</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/hero"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Hero">🦸‍♂️</span>
              {hovered ? <span>Hero</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/elf"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Elf">🧝‍♀️</span>
              {hovered ? <span>Elf</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/tsundere"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Tsundere">😡</span>
              {hovered ? <span>Tsundere</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/yandere"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Yandere">🔪</span>
              {hovered ? <span>Yandere</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/kuudere"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Kuudere">🥶</span>
              {hovered ? <span>Kuudere</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/royalty"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Royalty">👑</span>
              {hovered ? <span>Royalty</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/assistant"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Assistant">💁</span>
              {hovered ? <span>Assistant</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/robot"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Robot">🤖</span>
              {hovered ? <span>Robot</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/object"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Object">🗿</span>
              {hovered ? <span>Object</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/non-binary"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Non-binary">🌈</span>
              {hovered ? <span>Non-binary</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/deredere"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Deredere">🥰</span>
              {hovered ? <span>Deredere</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/feet"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Feet">👣</span>
              {hovered ? <span>Feet</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/movie"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Movie">🎥</span>
              {hovered ? <span>Movie</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/arab"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Arab">🕌</span>
              {hovered ? <span>Arab</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/myth"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Myth">🧜‍♀️</span>
              {hovered ? <span>Myth</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/muslim"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Muslim">☪️</span>
              {hovered ? <span>Muslim</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/religion"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Religion">⛪</span>
              {hovered ? <span>Religion</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/historical"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Historical">🏰</span>
              {hovered ? <span>Historical</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/giant"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Giant">🧖🏼‍♀️</span>
              {hovered ? <span>Giant</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/succubus"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Succubus">👿</span>
              {hovered ? <span>Succubus</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/lesbian"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Lesbian">👩‍❤️‍👩</span>
              {hovered ? <span>Lesbian</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/action"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Action">💥</span>
              {hovered ? <span>Action</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/alien"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Alien">👽</span>
              {hovered ? <span>Alien</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/vtuber"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="VTuber">👩🏼‍💻</span>
              {hovered ? <span>VTuber</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/dandere"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Dandere">😶</span>
              {hovered ? <span>Dandere</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/non-english"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Non-English">🌎</span>
              {hovered ? <span>Non-English</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/fandom"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Fandom">🔍</span>
              {hovered ? <span>Fandom</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/books"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Books">📚</span>
              {hovered ? <span>Books</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/philosophy"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Philosophy">📙</span>
              {hovered ? <span>Philosophy</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/manga"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Manga">📚</span>
              {hovered ? <span>Manga</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/politics"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Politics">📜</span>
              {hovered ? <span>Politics</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/asexual"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Asexual">🌈</span>
              {hovered ? <span>Asexual</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/folklore"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Folklore">📚</span>
              {hovered ? <span>Folklore</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/dilf"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="DILF">🧔</span>
              {hovered ? <span>DILF</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/detective"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Detective">🕵️‍♀️</span>
              {hovered ? <span>Detective</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/seinen"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Seinen">📚</span>
              {hovered ? <span>Seinen</span> : ''}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/tag/blog"}
              className={({ isActive }) => clsx([isActive && styles.active])}
            >
              <span role="img" aria-label="Blog">📖</span>
              {hovered ? <span>Blog</span> : ''}
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SideNavigation;
import styles from './ContentContainer.module.scss';
import clsx from 'clsx';

export default function ContentContainer ({ children, title, subTitle, Content, highlighted, loadingPercentage, RightContent, hideHeading, styleObj={}, Content2 }) {
  const containerClassName = clsx([
    styles.contentContainer,
    highlighted && styles.highlighted
  ]);

  return (
    <div style={styleObj} className={containerClassName}>
     {title == undefined ? <></> :  <header>
        <div className={styles.headerInfo}>
          { Content && Content }
          {Content2 == undefined ? <></> : Content2}
          {!hideHeading && (
            <div>
              <h2>{title}</h2>
              {subTitle && <p>{subTitle}</p>}
            </div>
          )}
        </div>
        {RightContent && RightContent}
        {loadingPercentage && <div className={styles.loadingPercentage} style={{ width: `${loadingPercentage}%`}} />}
      </header> }
      <div className={styles.mainContent} style={title == undefined ? { borderRadius: '15px', height: '500px' } : {}}>
        {children}
      </div>
    </div>
  );
}

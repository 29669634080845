import styles from './DashboardLayout.module.scss';

import SideNavigation from '../SideNavigation/SideNavigation';
import { useEffect, useState, useRef } from 'react';
import MenuIcon from '../../assets/icons/MenuIcon.js';
import Input from '../Input/Input.js';
import LabeledCheckbox from '../Checkbox/LabeledCheckbox.js';

import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from "jwt-decode";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';

import axiosRequest from '../../utils/request';

import Button from '../Button/Button.js';

import AgeConfirmModal from '../AgeConfirmModal';

import CustomDropdown from '../Dropdown/index.js';

export default function DashboardLayout({ disregardHeight, title, RightContent, OuterContent, isBurn, children, subtitle, headerStyle, hideFooter }) {

  const [menuOpen, setMenuOpen] = useState(false);
  const lockIfUnauthorized = ['account', 'billing', 'settings', 'for-you', 'following', 'my-characters', 'favorites', '/chat']

  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const user_data = useSelector(state => state.userData);
  const loggedIn = useSelector(state => state.loggedIn);
  const nsfw = useSelector(state => state.nsfw);
  const [nsfwEnabled, setNSFW] = useState(nsfw);

  const token = localStorage.getItem('jwt');
  const [authorized, setAuthorized] = useState(true);

  const [showAgeModal, setShowAgeModal] = useState(false);

  useEffect(() => {
    setNSFW(nsfw);
  }, [nsfw]);

  const handleNSFW = () => {
    if (!nsfwEnabled) {
      setShowAgeModal(true);
    } else {
      updateNSFW(false);
    }
  };

  const handleAgeConfirm = () => {
    setShowAgeModal(false);
    updateNSFW(true);
  };

  const updateNSFW = (value) => {
    dispatch({
      type: 'UPDATE_NSFW',
      data: value
    });
    setNSFW(value);
  };

  async function logout(fullPath) {
    try {
      navigateTo('/login', { redirectPath: fullPath });

      localStorage.removeItem('jwt');
      dispatch({
        data: null,
        type: 'UPDATE_USER_DATA'
      });

      dispatch({
        data: false,
        type: 'UPDATE_LOGGED_IN'
      });

      handleLogout();
    } catch (e) {
      console.log('ERROR: ', e);
    }
  };

  async function handleLogout() {
    try {
      const result = await axiosRequest('post', 'logout', {});
      if (result.data.success) {
        toast.info('Please log back in.');
        return;
      }
    } catch (e) {
      console.log('Error @ handleLogout -> ', e);
      return;
    }
  }

  useEffect(() => {
    document.title = title + ' - Mindscape';

    /*let url = window.location.href.split('/')[3];
    let lockedPage = lockIfUnauthorized.includes(url);

    if(window.location.href.includes('/character/create')) {
      lockedPage = true;
    };*/

    let url = window.location.href;

    const fullPath = location.pathname;

    let lockedPage = false;
    let x, length;
    for (x = 0, length = lockIfUnauthorized.length; x < length; x += 1) {
      let toCheck = lockIfUnauthorized[x];

      if (url.includes(toCheck)) {
        lockedPage = true;
        break;
      }
    }

    if ((user_data == null || token == null) && lockedPage) {
      dispatch({
        data: false,
        type: 'UPDATE_LOGGED_IN'

      });
      dispatch({
        data: null,
        type: 'UPDATE_USER_DATA'
      });

      navigateTo('/login', { state: { redirectPath: fullPath } });
      toast.error('Please login again.');
      return;
    } else if (token != null && lockIfUnauthorized.includes(url)) {

      let decodedToken = jwtDecode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) {
        logout(fullPath);
        return;
      };
    };

    setAuthorized(true);
  }, []);


  useEffect(() => {
    document.title = (title ? `${title} - ` : '') + 'Mindscape';
  }, [title]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const endpoint = `user/${user_data.username}${'?isOwnProfile=true'}`;

        const { data, status } = await axiosRequest('get', endpoint, null, jwt);

        if (status == 200 && data) {
          dispatch({ type: 'UPDATE_USER_DATA', data: data });
        } else {
          //throw new Error('Failed to fetch profile data');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        //toast.error('Error fetching profile data.')
      };
    };
    if (loggedIn) {
      try {
        fetchProfileData()
      } catch (e) {

      }
    }
  }, [])


  const crtRef = useRef(null);
  const [width, setWidth] = useState(125);
  const [height, setHeight] = useState(200);
  const [gridSizeX, setGridSizeX] = useState(12);
  const [gridSizeY, setGridSizeY] = useState(18);
  const [flickerRate, setFlickerRate] = useState(0.02);
  const [frameRate, setFrameRate] = useState(50);

  useEffect(() => {
    const crt = crtRef.current;

    const createCircles = () => {
      for (let x = 0; x < width; x += gridSizeX) {
        for (let y = 0; y < height; y += gridSizeY) {
          const circle = document.createElement('div');
          circle.className = styles.circle;
          circle.style.left = `${x}px`;
          circle.style.top = `${y}px`;
          crt.appendChild(circle);
        }
      }
    };

    const animateCircles = () => {
      const circles = crt.querySelectorAll(`.${styles.circle}`);
      circles.forEach(circle => {
        if (Math.random() < flickerRate) {
          circle.style.opacity = Math.random() < 0.5 ? '1' : '0';
        }
      });
    };

    createCircles();

    const intervalId = setInterval(animateCircles, 1000 / frameRate);

    return () => {
      clearInterval(intervalId);
      while (crt.firstChild) {
        crt.removeChild(crt.firstChild);
      }
    };
  }, [width, height, gridSizeX, gridSizeY, flickerRate, frameRate]);

  const createOption = ['Character', 'Image'];


  const handleCreateOption = (option) => {
    switch (option) {
      case 'Character':
        navigateTo('/character/create');
        break;
      case 'Image':
        navigateTo('/image/create');
        //toast.info('Coming Soon!');
        break;
    }
  }

  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const setNewEndTime = () => {
      const newEndTime = Date.now() + 12.28 * 60 * 60 * 1000; // 18.28 hours from now
      localStorage.setItem('discountEndTime', newEndTime.toString());
      return newEndTime;
    };

    let endTime = localStorage.getItem('discountEndTime');

    if (!endTime || parseInt(endTime) <= Date.now()) {
      // Set new end time if not set or if it's in the past
      endTime = setNewEndTime();
    }

    const timer = setInterval(() => {
      const now = Date.now();
      const end = parseInt(endTime);
      const difference = Math.max(0, Math.floor((end - now) / 1000));

      setTimeLeft(difference);

      if (difference <= 0) {
        clearInterval(timer);
        setNewEndTime(); // Reset timer when it reaches zero
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <>
      {!authorized ? <></> :
        <>
          <div
            style={{
              width: '100%',
              backgroundColor: '#DD8DEE80',
              color: 'white',
              padding: '4px 0',
              fontWeight: 'bold',
              fontSize: '14px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={e => {
              e.preventDefault();
              navigateTo('/membership')
            }}
          >
            <span style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}>
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20ZM13 7H11V13H17V11H13V7Z" fill="white" />
              </svg>
              50% off for a LIMITED time, use code FALL50 | {formatTime(timeLeft)}
            </span>
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              minHeight: '98vh',
              width: '100vw',
              padding: '10px',
              height: '100vh',
              overflow: 'hidden',
              backgroundColor: '#000',
            }}
          >
            <div
              style={{
                backgroundImage: 'url("/landing_hero.jpg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '98vh',
                width: '100%',
                position: 'relative',
                display: 'flex',
                minHeight: '100%',
                width: '100%',
                flex: 1,
                flexDirection: 'column',
                borderRadius: '8px',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'rgba(220, 141, 238, 0.676)', // Assuming this is the color for 'camo-500'
                transition: 'colors 0.3s ease', // Approximation of transition-colors
                overflow: 'hidden',
              }}
            >
              <div className="sticky top-[14px] z-10 sm:top-[13px]">
                <div className="relative">
                  <header className="relative flex justify-between">
                    <div className="flex flex-1 items-center justify-between gap-5 overflow-x-hidden">
                      <div className="flex flex-1 items-center justify-between sm:justify-start">
                        <nav className="ml-6 items-center gap-2 xs:flex" style={{ display: 'flex', flexDirection: 'row', color: '#fff', textShadow: '1px 2px 3px rgba(0, 0, 0, 0.25)', fontSize: '16px', fontWeight: 'bold', textTransform: 'uppercase' }}>
                          <div
                            className="px-2 py-2 sm:px-4"
                            onClick={e => {
                              e.preventDefault();
                              navigateTo('/');
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <img style={{ height: '70px', width: '75px', margin: '10px' }} src="/logo.png" />
                          </div>
                          <div className="hidden sm:block px-2 py-2 sm:px-4">
                            <Input
                              placeholder={'Click here to search for characters'}
                              style={{ width: '375px', cursor: 'pointer' }}
                              onClick={e => {
                                e.preventDefault();
                                navigateTo('/search')
                              }}
                            />
                          </div>
                          <div className=" px-2 py-2 sm:px-4">
                            <LabeledCheckbox
                              id={'nsfw-enabled'}
                              checked={nsfwEnabled}
                              onChange={handleNSFW}>NSFW</LabeledCheckbox>
                          </div>
                          <div className=" px-2 py-2 sm:px-4">
                            <a
                              className="text-camo-300"
                              style={{ cursor: 'pointer' }}
                              onClick={e => {
                                e.preventDefault();
                                navigateTo('/about')
                              }}
                            >
                              About
                            </a>
                          </div>
                          {<button className={styles.menuButton} onClick={() => setMenuOpen(true)}>
                            <MenuIcon />
                          </button>}
                          {/*<div className=" px-2 py-2 sm:px-4">
                          <a className="text-camo-300" href="/leaderboard">Leaderboard</a>
                          </div> */}
                          <div className="hidden sm:block px-2 py-2 sm:px-4">
                            <a
                              className="text-camo-300"
                              href="/"
                              onClick={e => {
                                e.preventDefault();

                                if (loggedIn) {
                                  localStorage.removeItem('jwt');

                                  dispatch({
                                    data: null,
                                    type: 'UPDATE_USER_DATA'
                                  });

                                  dispatch({
                                    data: false,
                                    type: 'UPDATE_LOGGED_IN'
                                  });

                                  navigateTo('/login');
                                } else {
                                  navigateTo('/login');
                                }
                              }}
                            >{loggedIn ? 'Sign Out' : 'Login'}</a>
                          </div>
                          {loggedIn ? <></> : <div className="hidden sm:block px-2 py-2 sm:px-4">
                            <a
                              className="text-camo-300"
                              href="/"
                              onClick={e => {
                                e.preventDefault();

                                navigateTo('/signup');
                              }}
                            >Sign Up</a>
                          </div>}
                          <div className="hidden sm:flex items-center">
                            {(
                              <>
                                <div>
                                  <Button
                                    onClick={() => navigateTo('/character/create')}
                                    className="bg-pink-400 hover:bg-pink-300 text-white font-bold py-2 px-4 rounded"
                                    style={{
                                      borderTopRightRadius: '0px',
                                      borderBottomRightRadius: '0px'
                                    }}
                                  >
                                    + Create
                                  </Button>
                                </div>
                                <div>
                                  <CustomDropdown
                                    options={createOption}
                                    value={'➡️'}
                                    onChange={handleCreateOption}
                                    create={true}
                                    chat={false}
                                    removeArrow
                                    emojiSelector
                                    selectedStyle={
                                      {
                                        backgroundColor: '#fff',
                                        color: '#000',
                                        borderLeft: '4px dashed #fdc1dc',
                                        width: '50px',
                                        padding: '13px 5px',
                                        borderTopLeftRadius: '0px',
                                        borderBottomLeftRadius: '0px'
                                      }
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </nav>
                      </div>
                      <div className="block transition-transform duration-500 xs:hidden rotate-0">
                        <button aria-label="Open Navigation menu" className="text-yellow-300 uppercase flex items-center gap-1 disabled:cursor-not-allowed">
                          <svg viewBox="0 0 24 24" style={{ height: "24px", width: "24px" }}>
                            <use xlinkHref="/icons/library.svg#menu"></use>
                          </svg>
                        </button>
                      </div>
                      <div className="hidden overflow-x-auto xs:gap-10 sm:flex"></div>
                    </div>
                  </header>
                  <div className={styles.dashboard}>
                    <SideNavigation menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                    <main>
                      <div style={disregardHeight ? { height: '100vh' } : {}} className={styles.container}>
                        <header style={headerStyle} className={styles.header}>
                          {!title ? <></> : <div
                            className={styles.column}
                            style={{ maxWidth: '650px' }}
                          >
                            <h1 style={{ fontSize: '24px' }}>{title}</h1>
                            <h4 style={{ fontSize: '14px', color: '#fff' }}>{subtitle}</h4>
                          </div>}
                          <div>
                            {RightContent}
                          </div>
                        </header>
                        {children}
                        {hideFooter ? <></> : <div className={styles.footer}>
                          <div className={styles.footerContent}>
                            <div className={styles.row}>
                              <img style={{ height: '70px', width: '75px', margin: '10px' }} src="/logo.png" />
                              <h1 style={{ fontSize: '18px', color: '#fff', fontWeight: 'bold' }}>Mindscape</h1>
                            </div>
                            <p style={{ width: '425px' }}>Mindscape is a roleplaying platform that allows you to chat with and create an endless amount of AI characters.</p>
                          </div>
                          <div className={styles.footerLinks}>
                            <div>
                              <h4>PLATFORM</h4>
                              <ul>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/tag/nsfw')
                                  }}
                                >
                                  NSFW AI chat
                                </li>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/tag/female')
                                  }}
                                >
                                  AI Girlfriends
                                </li>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/tag/male')
                                  }}
                                >
                                  AI Boyfriends
                                </li>
                                <li>AI Hentai</li>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/image/create')
                                  }}
                                >
                                  NSFW Image Generator
                                </li>
                              </ul>
                            </div>
                            <div>
                              <h4>RESOURCES</h4>
                              <ul>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    toast.info('Affiliate program coming soon!');
                                  }}
                                >Become an Affiliate</li>
                                <li
                                  onClick={() => window.open('https://discord.com/invite/mindscapeis', '_blank')}
                                >Support</li>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/legal')
                                  }}
                                >Legal</li>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/legal/guidelines')
                                  }}
                                >Community Guidelines</li>
                                <li

                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/legal/content-removal')
                                  }}
                                >Content removal</li>
                                <li>FAQ</li>
                                <li>Download</li>
                              </ul>
                            </div>
                          </div>
                        </div>}
                      </div>
                    </main>
                    {OuterContent}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backdropFilter: 'blur(.5px)',
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                zIndex: 2
              }}
            ></div>
            <div className={styles.crt} ref={crtRef} style={{ width, height }}></div>
          </div>
        </>
      }
      {
        <AgeConfirmModal
          isOpen={showAgeModal}
          onClose={() => setShowAgeModal(false)}
          onConfirm={handleAgeConfirm}
        />
      }
    </>
  );
}

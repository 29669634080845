import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/DynamicCategories.module.scss';
import axiosRequest from '../utils/request';
import CustomDropdown from '../components/Dropdown';

import formatNumber from '../utils/formatNumber';

import AgeConfirmModal from '../components/AgeConfirmModal';

import { useSelector, useDispatch } from 'react-redux';

import updateImageUrl from '../utils/updateImageUrl';

const NSFWOverlay = ({ onShow, likes }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <p className={styles.ratingText}>This Character is rated XXX</p>
        <button className={styles.showButton} onClick={onShow}>Show</button>
      </div>
      <div className={styles.likes}>
        <span>♥</span> {likes}
      </div>
    </div>
  );
};

export default function CategoriesDynamic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loggedIn, setLoggedIn] = useState(true);
  const [category, setCategory] = useState('');
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { categoryname } = useParams();
  const observer = useRef();

  const [sortBy, setSortBy] = useState('Most Liked');
  const sortOptions = ['Most Liked', 'Popular', 'Trending', 'Recent Hits', 'New'];

  const lastCharacterElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    if (categoryname) {
      setCategory(categoryname.charAt(0).toUpperCase() + categoryname.slice(1));
      setCharacters([]);
      setPage(1);
      setHasMore(true);
      setLoading(true);
    }
  }, [categoryname, sortBy]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchCharacters();
    }, 500);

    return () => clearTimeout(timer);
  }, [categoryname, page, sortBy]);

  const fetchCharacters = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      let sortValue;
      switch (sortBy) {
        case 'Most Liked':
          sortValue = 'mostLiked';
          break;
        case 'Popular':
          sortValue = 'popular';
          break;
        case 'New':
          sortValue = 'new';
          break;
        default:
          sortValue = 'mostLiked';
      }
      const result = await axiosRequest('get', `characters/tag/${categoryname}?page=${page}&limit=20&sort=${sortValue}`, null, jwt);
      if (result.data && result.data.characters) {
        setCharacters(prev => page === 1 ? result.data.characters : [...prev, ...result.data.characters]);
        setHasMore(result.data.hasMore);
      } else {
        throw new Error('Failed to fetch characters');
      }
    } catch (error) {
      console.error('Error fetching characters:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setLoading(false);
    }
  };

  const renderSkeletonLoader = () => (
    <div className={styles.skeletonCharacterGrid}>
      {[...Array(12)].map((_, index) => (
        <div key={index} className={styles.skeletonCharacterCard}>
          <div className={styles.skeletonImageContainer}></div>
          <div className={styles.skeletonContent}>
            <div className={styles.skeletonTitle}></div>
            <div className={styles.skeletonDescription}></div>
          </div>
        </div>
      ))}
    </div>
  );

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    setPage(1);
    setCharacters([]);
    setHasMore(true);
    setLoading(true);
  };

  const nsfw = useSelector(state => state.nsfw);
  const [nsfwEnabled, setNSFW] = useState(nsfw);
  const [showAgeModal, setShowAgeModal] = useState(false);

  const handleShowNSFW = () => {
    setShowAgeModal(true);
  };

  const handleConfirmAge = () => {
    dispatch({
      type: 'UPDATE_NSFW',
      data: true
    });
    setNSFW(true);
    setShowAgeModal(false);
  };

  useEffect(() => {
    setNSFW(nsfw);
  }, [nsfw]);

  return (
    <>
      {!loggedIn ? (
        <></>
      ) : (
        <DashboardLayout
          title={`${category} AI Characters for Roleplay Chat`}
          subtitle={`Explore the possibilities of ${category} AI chatbots: Engage in AI chat and connect with your virtual companion.`}
        >
          <div className={styles.container}>
            <div className={styles.filterRow}>
              <CustomDropdown
                options={sortOptions}
                value={sortBy}
                onChange={handleSortChange}
                alt={true}
              />
              <span
                className={styles.discoverNew}
                onClick={e => {
                  e.preventDefault();
                  navigate('/search')
                }}
              >← discover new characters</span>
            </div>
            {loading && characters.length === 0 ? (
              renderSkeletonLoader()
            ) : (
              <div className={styles.characterGrid}>
                {characters.map((character, index) => (
                  <div
                    key={character._id}
                    ref={index === characters.length - 1 ? lastCharacterElementRef : null}
                    className={styles.characterCard}
                    onClick={(e) => {
                      e.preventDefault();
                      
                      if (character.nsfw && !nsfwEnabled) return;
  
                      navigate(`/character/${character['_id']}`, { state: character });
                    }}
                  >
                    <div className={styles.imageContainer}>
                      <img src={updateImageUrl(character.imageUrl) || "/placeholder.avif"} alt={character.name} />
                      {character.nsfw && !nsfwEnabled && (
                        <NSFWOverlay onShow={handleShowNSFW} likes={character.likes} />
                      )}
                      <div className={styles.likes}>♥ {character.likes}</div>
                      {character.nsfw && <div className={styles.nsfwTag}>NSFW</div>}
                    </div>
                    <h3>{character.name}</h3>
                    <p>{character.description}</p>
                    <div className={styles.cardFooter}>
                      <span className={styles.messages}>{(formatNumber(character.messages) || 0).toLocaleString()} 💬</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!loading && !hasMore && characters.length === 0 && (
              <div className={styles.emptyState}>
                <h2>It's a bit empty here</h2>
                <p>Create the first character for this category!</p>
                <button
                  className={styles.exploreButton}
                  onClick={e => {
                    e.preventDefault();
                    if (loggedIn) {
                      navigate('/character/create');
                    } else {
                      navigate('/login');
                    }
                  }}
                >Create a Character</button>
              </div>
            )}
          </div>
        </DashboardLayout>
      )}
      {
        <AgeConfirmModal
          isOpen={showAgeModal}
          onClose={() => setShowAgeModal(false)}
          onConfirm={handleConfirmAge}
        />
      }
    </>
  );
}
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Header from "../components/Header/Header";

import styles from "../assets/pages/Signup.module.scss";

import Input from '../components/Input/Input';
import Button from '../components/Button/Button';

import { containsNumber, containsCapitalLetter, isValidEmail } from '../utils/regex';
import axiosRequest from '../utils/request';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';

export default function Signup() {
  const location = useLocation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [step, setStep] = useState(0);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmedPassword, setConfirmedPassword] = useState();
  const [username, setUsername] = useState();
  const [ref, setRef] = useState('');
  const [isAdmin, setIsAdmin] = useState(true);

  const [step0Text, setStep0Text] = useState('');
  const [step1Text, setStep1Text] = useState('');
  const [step2Text, setStep2Text] = useState('');
  const [step3Text, setStep3Text] = useState('');
  const [step4Text, setStep4Text] = useState('');
  const [step5Text, setStep5Text] = useState('');

  const [passwordMsg, setPasswordMsg] = useState('Password is too short!');
  const [passwordMsgStyle, setPasswordMsgStyle] = useState({ margin: '5px', color: 'red' });
  const [div1Style, setDiv1Style] = useState({});
  const [div2Style, setDiv2Style] = useState({});
  const [div3Style, setDiv3Style] = useState({});

  const [showRegister, setRegister] = useState(true);

  const [passwordValid, setPasswordValid] = useState(false);

  const [redirectPath, setRedirectPath] = useState()

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state && location.state.redirectPath) {
      const fullPath = location.state.redirectPath;

      setRedirectPath(fullPath);
    }
  }, [location]);

  useEffect(() => {
    if (initialLoad) {
      // type out first section
      setTimeout(async () => {
        loadInitialText();
      }, 500);

      setInitialLoad(false);
    };

    async function loadInitialText() {
      await animateTyping('Welcome to Mindscape!', setStep0Text);
      setTimeout(() => {
        setStep(1);
        animateTyping('Lets get started.', setStep1Text);
      }, 1000);

      setTimeout(() => {
        setStep(2);
      }, 2000)

      setTimeout(() => {
        animateTyping('→ Enter your email', setStep2Text, 12.5);
      }, 2250)


    }


  }, []);

  useEffect(() => {
    if (step == 3) {
      setPasswordValid(false);
      if (password.length < 8) {
        setPasswordMsg('Password is too short!');
        setPasswordMsgStyle({ margin: '5px', color: 'red' });
        setDiv1Style({ backgroundColor: '#b2b2b23c' });
        setDiv2Style({ backgroundColor: '#b2b2b23c' });
        setDiv3Style({ backgroundColor: '#b2b2b23c' });
        return;
      }

      setDiv1Style({ backgroundColor: 'green' });

      if (!containsCapitalLetter(password)) {
        setPasswordMsg('Must contain a capital!');
        setPasswordMsgStyle({ margin: '5px', color: 'red' });
        setDiv2Style({ backgroundColor: '#b2b2b23c' });
        setDiv3Style({ backgroundColor: '#b2b2b23c' });
        return;
      }

      setDiv2Style({ backgroundColor: 'green' });

      if (!containsNumber(password)) {
        setPasswordMsg('Must contain a number!');
        setPasswordMsgStyle({ margin: '5px', color: 'red' });
        setDiv3Style({ backgroundColor: '#b2b2b23c' });
        return;
      }

      setDiv3Style({ backgroundColor: 'green' });

      setPasswordMsg('Password looks good!');
      setPasswordMsgStyle({ margin: '5px', color: 'green' });
      setPasswordValid(true);
    }

    if (step == 4) {
      setPasswordValid(false)
      if (confirmedPassword != password) {
        setPasswordMsg("Password's don't match!");
        setPasswordMsgStyle({ margin: '5px', color: 'red' });
        setDiv1Style({ backgroundColor: '#b2b2b23c' });
        setDiv2Style({ backgroundColor: '#b2b2b23c' });
        setDiv3Style({ backgroundColor: '#b2b2b23c' });
        return;
      };

      if (confirmedPassword.length < 8) {
        setPasswordMsg('Password is too short!');
        setPasswordMsgStyle({ margin: '5px', color: 'red' });
        setDiv1Style({ backgroundColor: '#b2b2b23c' });
        setDiv2Style({ backgroundColor: '#b2b2b23c' });
        setDiv3Style({ backgroundColor: '#b2b2b23c' });
      }

      setDiv1Style({ backgroundColor: 'green' });

      if (!containsCapitalLetter(confirmedPassword)) {
        setPasswordMsg('Must contain a capital!');
        setPasswordMsgStyle({ margin: '5px', color: 'red' });
        setDiv2Style({ backgroundColor: '#b2b2b23c' });
        setDiv3Style({ backgroundColor: '#b2b2b23c' });
        return;
      }

      setDiv2Style({ backgroundColor: 'green' });

      if (!containsNumber(confirmedPassword)) {
        setPasswordMsg('Must contain a number!');
        setPasswordMsgStyle({ margin: '5px', color: 'red' });
        setDiv3Style({ backgroundColor: '#b2b2b23c' });
        return;
      }

      setDiv3Style({ backgroundColor: 'green' });

      setPasswordMsg('Password looks good!');
      setPasswordMsgStyle({ margin: '5px', color: 'green' });
      setPasswordValid(true);
    }
  }, [password, confirmedPassword]);

  useEffect(() => {
    const url = window.location.href;

    const params = new URLSearchParams(new URL(url).search);

    const invCode = params.get('ref');

    if (invCode) {
      setRef(invCode);
    };
  }, []);

  async function animateTyping(inputText, setStateFunction, delay = 25) {
    let index = -1;

    const typeCharacter = () => {
      if (index < inputText.length) {
        setStateFunction(prevState => prevState + inputText.charAt(index));
        index++;
        setTimeout(typeCharacter, delay);
      }
    };

    typeCharacter();
  };

  async function register() {
    try {
      let hasError = false;

      if (email == undefined) {
        hasError = true;
      };

      if (password == undefined) {
        hasError = true;
      };

      if (hasError) {
        toast.error('Must fill out all fields!');
        return;
      };


      let result = await axiosRequest('post', 'signup', { email, password, username, ref });

      if (result.data.success) {
        if(!redirectPath) {
          navigate('/verify', { state: { email } });
        } else {
          navigate('/verify', { state: { email, redirectPath } });
        }
        return;
      } else {
        toast.error(result.data.msg);
        return;
      }
    } catch (e) {
      console.log('Error at sign up call -> ', e);
      toast.error('Unknown error signing up.');
      return;
    }
  }

  return (
    <DashboardLayout>
      <div className={styles.loginPage} >
       {/* <img  style={{ height: '100vh', width: '50%'}}/> */}
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.text}>
              <p>{step0Text} {" "} {step == 0 ? <span className={styles.blinker} /> : ""}</p>
            </div>
            <div className={styles.text}>
              <p>{step1Text} {" "} {step >= 1 ? <span className={styles.blinker} /> : ""}</p>
            </div>
            {step >= 2 ? <div className={styles.inputLabel}>
              <Input
                style={{ width: '100%',  backgroundColor: ''  }}
                value={email}
                onChange={e => { setEmail(e.target.value) }}
                label={step < 3 ? step2Text : "✓ Enter your email"}
                titleWhite
                id={'email'}
                type={'email'}
              // disabled={step >= 3 ? true : false}
              />
              {step >= 3 ? <></> : <Button
                style={{
                  marginTop: '12px',
                  width: '100%',
                }}
                onClick={() => {
                  setTimeout(() => {
                    if (!isValidEmail(email)) {
                      toast.error('Invalid email format!');
                    } else {
                      setStep(3)
                      animateTyping('→ Create a password', setStep3Text);
                    }
                  }, 250)
                }}
              >Continue</Button>}
            </div> : <></>}
            {step >= 3 ? <div className={styles.inputLabel}>
              <Input
                style={{ width: '100%' }}
                value={password}
                onChange={e => { setPassword(e.target.value) }}
                label={step < 4 ? step3Text : "✓ Create a password"}
                id={'password'}
                type={'password'}
                titleWhite
              // disabled={step >= 4 ? true : false}
              />
              {step >= 4 ? <></> : <Button
                style={passwordValid ? {
                  marginTop: '12px',
                  width: '100%',
                } : { marginTop: '12px', width: '100%', opacity: '0.5' }}
                onClick={() => {
                  setStep(4);
                  setTimeout(() => {
                    animateTyping('→ Confirm your password', setStep4Text);
                  }, 250)
                }}
                disabled={passwordValid ? false : true}
              >Continue</Button>}
            </div> : <></>}
            {
              step >= 4 ? <div className={styles.inputLabel}>
                <Input
                  style={{ width: '100%',    }}
                  value={confirmedPassword}
                  onChange={e => {
                    setConfirmedPassword(e.target.value)
                  }}
                  label={step4Text}
                  id={'confirmedPassword'}
                  type={'password'}
                  titleWhite
                />
                {!showRegister || step >= 5 ? <></> : <Button
                  style={passwordValid ? {
                    marginTop: '12px',
                    width: '100%',
                  } : { marginTop: '12px', width: '100%', opacity: '0.5' }}
                  onClick={() => {
                    setStep(5);
                    setTimeout(() => {
                      animateTyping("→ Username", setStep5Text);
                    }, 250)
                  }}
                  disabled={passwordValid ? false : true}
                >Continue</Button>}
              </div> : <></>
            }
            {
              step >= 5 ? <div className={styles.inputLabel}>
                <Input
                  style={{ width: '100%',    }}
                  value={username}
                  onChange={e => {
                    setUsername(e.target.value)
                  }}
                  label={step5Text}
                  id={'username'}
                  type={'text'}
                  titleWhite
                />
                {!showRegister ? <></> : <Button
                  style={passwordValid ? {
                    marginTop: '12px',
                    width: '100%',
                  } : { marginTop: '12px', width: '100%', opacity: '0.5' }}
                  onClick={() => {
                    register()
                  }}
                  disabled={passwordValid ? false : true}
                >Register</Button>}
              </div> : <></>
            }
            {
              step >= 3 ? <div>
                <div style={{marginTop: '12px'  }} className={styles.row}>
                  <div style={div1Style} className={styles.Jc3fE}></div>
                  <div style={div2Style} className={styles.Jc3fE}></div>
                  <div style={div3Style} className={styles.Jc3fE}></div>
                </div>
                <div style={passwordMsgStyle}>
                  <h5>{passwordMsg}</h5>
                </div>
                <div style={{ marginLeft: '5px', marginTop: '25px', color: '#fff' }}>
                  <small>Make sure it's at least 8 characters including a capital and number!</small>
                </div>
              </div> : <></>
            }
          </div>
          {
            step >= 4 ? <div style={{
              margin: '10px',
              backgroundColor: '#dc8dee4a',
              backgroundFilter: 'blur(5px)',
              padding: '8px',
              borderRadius: '15px',
              color: '#fff'
            }}>
              <small><b>By creating an account, you agree to the Terms of Service and Privacy Policy.</b></small>
            </div> : <></>}
        </div>
      </div>
    </DashboardLayout>
  )
}
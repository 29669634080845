import Input from "../components/Input/Input.js";
import Title from '../components/Title/Title.js';
import styles from "../assets/pages/Login.module.scss";
import Button from '../components/Button/Button';
import LabeledCheckbox from '../components/Checkbox/LabeledCheckbox';
import { useState } from 'react';
import Link from '../components/Link/Link.js';
import { useNavigate, useLocation } from 'react-router-dom';

import Header from "../components/Header/Header";

import axiosRequest from '../utils/request.js';

import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from "react";

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const rememberMe = useSelector(state => state.rememberMe);
  console.log('remember me !!!');

  const [email, setEmail] = useState(rememberMe ? rememberMe.email : '');
  const [password, setPassword] = useState();

  const [checked, setChecked] = useState(rememberMe ? rememberMe.enabled : false);

  const [step0Text, setStep0Text] = useState('');
  const [showToolTip, setShowToolTip] = useState(false)
  const [isElectron, setIsElectron] = useState(false);

  const [redirectPath, setRedirectPath] = useState()

  async function handleLogin() {
    try {
      setRememberMe();

      let hasError = false;

      if (email == undefined) {
        hasError = true;
      };

      if (password == undefined) {
        hasError = true;
      };

      if (hasError) {
        toast.error('Must fill out all fields!');
        return;
      };

      const deviceInfo = getDeviceInfo();

      let result = await axiosRequest('post', 'login', {
        email,
        password,
        deviceInfo
      });

      if (result.data.success) {
        dispatch({
          type: 'UPDATE_USER_DATA',
          data: result.data.user
        });

        dispatch({
          data: true,
          type: 'UPDATE_LOGGED_IN'
        });

        localStorage.setItem('jwt', result.data.token);

        if(!redirectPath) {
          //navigate(`/creator/${result.data.user.username}`);
          navigate('/for-you')
        } else {
          navigate(redirectPath);
        }
        return;
      } else {
        if (result.data.msg == 'Please verify your email!') {
          navigate('/verify');
          toast.error(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
        return;
      }
    } catch (e) {
      toast.error('Unknown error signing up.');
      return;
    }
  }

  async function setRememberMe() {
    let toSet = checked == false ? undefined : email;

    dispatch({
      data: { email: toSet, enabled: checked },
      type: 'UPDATE_REMEMBER_ME'
    })
  };

  const getDeviceInfo = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    return {
      timeZone: timeZone
    };
  };

  useEffect(() => {

    setTimeout(async () => {
      loadInitialText();
    }, 500);


    async function loadInitialText() {
      await animateTyping('Mhmmmm 👇', setStep0Text);
    }
  }, []);

  async function animateTyping(inputText, setStateFunction, delay = 75) {
    let characters = inputText.split('');  // Convert the input text into an array of characters
    let typedText = [];

    const typeCharacter = () => {
      if (characters.length > 0) {
        // Take the first character from the array and add it to the typed text
        typedText.push(characters.shift());
        // Update the state with the current typed text joined as a string
        setStateFunction(typedText.join(''));

        // Call the function again after a delay
        setTimeout(typeCharacter, delay);
      }
    };

    typeCharacter();
  }

  useEffect(() => {
    if (location.state && location.state.redirectPath) {
      const fullPath = location.state.redirectPath;

      setRedirectPath(fullPath);
    }
  }, [location]);

  return (
    <DashboardLayout
      title={''}
    >
      <div className={styles.loginPage}>
        <div className={styles.container}>
          <Title style={{ color: '#fff' }}>Login</Title>
          <div className={styles.elementWrapper}>
            <Input
              titleWhite
              value={email}
              onChange={e => { setEmail(e.target.value) }}
              label={"E-Mail"}
              style={{ backgroundColor: '' }}
              id={'email'} type={'email'}
            />
          </div>
          <div className={styles.elementWrapper}>
            <Input
              titleWhite
              onChange={e => { setPassword(e.target.value) }}
              label={"Password"}
              id={'password'}
              type={'password'}
              style={{ backgroundColor: '' }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleLogin();
                };
              }}
            />
          </div>
          <div className={styles.inputRow}>
            <div className={styles.elementWrapper}>
              <LabeledCheckbox id={'remember-me'} checked={checked} onChange={e => {
                setChecked(!checked);
                setRememberMe();
              }}>Remember me</LabeledCheckbox>
            </div>
            {/*<Link style={{ color: '#fff' }} to={'/forgot'}>Reset Password</Link>*/}
          </div>
          <div className={styles.elementWrapper}>
            <Button
              onClick={() => handleLogin()}
            >Login</Button>
          </div>
          <p style={{ color: '#fff' }} className={styles.centered}>Don't have an account? <Link style={{ color: '#f77cb3' }} to={'/signup'}>Sign Up</Link></p>
          <p style={{ color: '#fff', marginTop: '10px' }} className={styles.centered}>{step0Text} {" "} <span className={styles.blinker} /></p>
          <div className={styles.tooltipWrapper}>
            <span style={{ marginTop: '5px' }} className={styles.tooltipIndicator}>i</span>
            <div
              className={styles.tooltipMessage}
            >
              Things are going to get wilder and weirder as time goes on.
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
